.valentines-plans-pw-popup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 20px;
    border: 2px solid #ff3366;
    border-radius: 8px;
}

.valentines-plans-pw-popup-title {
    margin-bottom: 20px;
}

.valentines-plans-pw-popup-input {
    padding: 15px;
    margin: 10px;
    width: 100%;
    border: 2px solid #ff3366;
    border-radius: 8px;
    outline: none;
}

.valentines-plans-pw-popup-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.valentines-plans-pw-popup-buttons button {
    padding: 15px 40px;
    margin-top: 10px;
    background-color: #ff3366;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    outline: none;
}

.valentines-plans-pw-popup-buttons button + button {
    margin-left: 5px;
}

.valentines-plans-pw-popup-buttons button:hover {
    background-color: #ff0033;
}

.valentines-plans-pw-popup-close {
    margin-right: 30px;
}