.valentines-common-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
}

.sakura-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('https://media.giphy.com/media/AdtvZ8gu9gZ32/giphy.gif') center/cover;
    z-index: -1;
}

img {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 100%;
}

button.back-button {
    margin-top: 20px;
    padding: 15px 30px;
    background-color: #ff6699;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    outline: none;
}

button.back-button:hover {
    background-color: #ff3366;
}