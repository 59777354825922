.valentines-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    background-color: #ffcccc;
}
  
nav {
    width: 100%;
    background-color: #ffcccc;
    margin-bottom: 40px;
}
  
ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
}
  
li {
    margin: 0 20px;
}
  
a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    font-size: 18px;
}
  
a:hover {
    color: #ff3366;
}
  
.image-container {
    text-align: center;
    margin-bottom: 20px;
}
  
.image-container img {
    width: 100%;
    max-width: 400px;
}
  
.button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
  
.button-container button.yes-button,
.button-container button.no-button {
    display: inline-block;
    margin: 0 10px;
    width: 150px;
    padding: 20px;
    font-size: 18px;
    background-color: #ff6699;
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    outline: none;
}
  
.button-container button.yes-button:hover,
.button-container button.no-button:hover {
    background-color: #ff3366;
}

.button-container button.plans-button {
    display: inline-block;
    margin: 0 10px;
    padding: 20px 30px;
    font-size: 18px;
    background-color: #ff3366;
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    outline: none;
}
  
.button-container button.plans-button:hover {
    background-color: #ff0033;
}