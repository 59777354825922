.valentines-plans-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; 
    background-color: #ffcccc;
}

.timeline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.timeline-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ff6699; 
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px; 
}

.line {
    width: 2px;
    height: 50px;
    background-color: #ff6699;
    margin-left: 19px;
}

.plan {
    font-size: 18px;
    display: flex;
    align-items: center;
}

.back-button {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 15px 30px;
    background-color: #ff6699;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    outline: none;
}

.back-button:hover {
    background-color: #ff3366;
}