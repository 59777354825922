.valentines-pw-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #ffcccc;
}

.password-input-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.password-input {
    padding: 15px;
    margin: 15px;
    border: 2px solid #ff6699;
    border-radius: 12px;
    outline: none;
    font-size: 18px;
}

.submit-button {
    padding: 15px 30px;
    margin-top: 15px;
    background-color: #ff6699;
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    outline: none;
    font-size: 18px;
}

.submit-button:hover {
    background-color: #ff3366;
}