.valentines-no-container {
    position: relative;
    background-color: #ffcccc;
}

button.back-button {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 15px 30px;
    background-color: #ff6699;
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    outline: none;
}

button.back-button:hover {
    background-color: #ff3366;
}

button.yes-button {
    display: inline-block;
    margin-top: 20px;
    padding: 20px 40px;
    background-color: #ff6699;
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    outline: none;
}

button.yes-button:hover {
    background-color: #ff3366;
}