body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f0f2f5;
}

.followers-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding: 20px;
}

.instructions {
    text-align: center;
    max-width: 600px; /* Adjust width as needed */
    margin-bottom: 20px;
}

.instructions h2 {
    font-size: 2rem; /* Increased font size */
    margin-bottom: 20px;
    color: #333;
}

.instructions ol {
    list-style-type: decimal;
    padding-left: 20px;
    text-align: left; /* Align text to the left for readability */
    margin: 0;
    font-size: 1.2rem; /* Increased font size for instructions */
}

.instructions li {
    margin-bottom: 10px;
    font-size: 1.2rem; /* Increased font size for list items */
}

.file-input {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 1rem;
    border: 2px solid #28a745; /* Color for "Choose File" button */
    border-radius: 4px;
    background-color: #28a745; /* Background color for "Choose File" button */
    color: #fff;
    cursor: pointer;
}

.file-input::-webkit-file-upload-button {
    font-size: 1rem;
    color: #fff;
    background: #28a745; /* Background color for "Choose File" button */
    border: none;
    padding: 10px;
    cursor: pointer;
}

.file-title {
    font-size: 1.5rem;
    margin: 20px 0;
    font-weight: bold;
    color: #333;
}

.scrollable-container {
    width: 100%;
    max-width: 800px; /* Adjust the maximum width as needed */
    max-height: 700px; /* Increased height */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.json-row {
    padding: 15px; /* Adjusted padding */
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 15px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column; /* Stack username above button */
    align-items: center;
    text-align: center;
}

.row-content {
    display: flex;
    flex-direction: column; /* Stack username above button */
    align-items: center; /* Center horizontally */
    width: 100%;
}

.username {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px; /* Adjust margin-bottom for a clear gap */
}

.json-row p {
    margin: 0; /* Remove extra margin */
}

.instagram-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff; /* Background color for "View Profile" button */
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    text-align: center;
    margin-top: 10px; /* Margin-top for spacing above button */
}

.instagram-button:hover {
    background-color: #0056b3;
}

.json-row a {
    color: #007bff; /* Color for Instagram link */
    text-decoration: none;
}

.json-row a:hover {
    text-decoration: underline;
}
