* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif, 'Roboto';
}

.inputBox {
    width: 250px;
}

.centerButton {
    display: block;
    text-align: center;
    margin: 0 auto;
}