a {
    text-decoration: none;
}
a:link, a:visited {
    color: black;
}
a:hover {
    color: blue;
}

.App {
    text-align: center;
}

.centered {
    height: 100vh; /* Magic here */
    display: flex;
    justify-content: center;
    align-items: center;
}